import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/hop-profili.jpg"
import Head from "../../components/head"

const HopProfili = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
  ]
  return (
    <Layout>
      <Head title="HOP Profili" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Hladno oblikovani profili "HOP"</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Hladno oblikovani profili
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div
            className="columns products-list"
            style={{ paddingBottom: "250px" }}
          >
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                showThumbnails={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>Hladno oblikovani profili "HOP"</b> <br /> Proizvode se na
                automatizovanoj liniji. <br />
                Maximalna debljina profila je 4 mm. <br /> Maximalna dužina
                profila je 10 metar. <br /> Maximalna širina trake koja može da
                se profiliše je 305 mm.
              </p>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default HopProfili
